import React from 'react'
import bg_vide from '../../videos/socialmedia4.mp4'
function About1() {
    
    return (
        <>
            <section id="about" className="bg-non-fixed">
            <video autoPlay loop muted className="bg_video">
                <source src={bg_vide} type="video/mp4"></source>
            </video>
            <div className="bg-inner-dark"></div>
            <div className="bg-50-r"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">About</h1>
                                        <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                        <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                        At FullScope, we are more than just a Social Media Marketing Agency; we are your partners in navigating the dynamic and ever-expanding digital landscape. With a full-stack approach to digital marketing, we cater to businesses striving to carve their niche in the competitive online sphere.
                                        </p>
                                    </article>
                                </div>
                            </div> 

                        </div> 
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="pad-l-45 pad-r-45 block">

                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">Our Unique Edge</h4>
                                <p>
                                What sets us apart is our collective experience in both influencer marketing and the tech industry. With our team's cumulative experience, we have garnered over 5 million followers across various social media platforms. In addition, our seasoned professionals bring over two decades of hands-on experience in the rapidly evolving tech industry. This unique blend of influencer insights and technical expertise enables us to craft strategies that are not only innovative but also rooted in a deep understanding of the digital landscape.  
                                </p>
                            </div>
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">Decades of Digital Prowess</h4>
                                <p>
                                With a cumulative experience of over 40 years in the tech world, we bring an in-depth understanding of the digital realm. From the early stages of the internet revolution to the current era of social media dominance, we have evolved alongside the digital landscape, accumulating insights and expertise that empower us to craft bespoke solutions for each of our clients.  
                                </p>
                            </div>
                            
                            
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                            <div className="clearfix"></div>
                                
                                {/* <div className="progress">
                                    <div className="progress-bar" style={{width: "70%"}} >

                                    </div>
                                    <span className="skill-value">Graphic Design <span> 70%</span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "95%"}}>

                                    </div>
                                    <span className="skill-value">Web Design <span> 95%</span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "90%"}}>

                                    </div>
                                    <span className="skill-value">Web Developing <span> 90%</span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "85%"}}>

                                    </div>
                                    <span className="skill-value">Java Scripting <span> 85%</span></span>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default About1
