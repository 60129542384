import React from 'react'
// import {FacebookShareButton,
//     PinterestShareButton,
//     TelegramShareButton,
//     LinkedinShareButton,
//     TwitterShareButton} from "react-share";
// import {FacebookIcon,
//     PinterestIcon,
//     TelegramIcon,
//     LinkedinIcon,
//     TwitterIcon} from "react-share";
function Team() {
  
    return (
        <>
        <section id="team">
            <div className="block color-scheme-2">
                <div className="container">
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                            <h1 className="head-title">Meet Our Team</h1>
                        </article>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/Jimmy.jpeg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Co Founder & CEO</p>
                                        <h4>Jim Whittle</h4>
                                        
                                    </div>

                                </figure>
                                {/* <div className="left-icon">
                                    <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://youtu.be/3aSb1WPK4Kg" 
                                            quote= "Anema react" hashtag="#react">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TelegramIcon round={false} size={36}></TelegramIcon>
                                            </TelegramShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://youtu.be/3aSb1WPK4Kg" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div> */}
                            </article> 
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/Rob.jpeg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Co Founder & CEO</p>
                                        <h4>Robert Murphy</h4>
                                        
                                    </div>

                                </figure>
                                {/* <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://youtu.be/3aSb1WPK4Kg" 
                                            quote= "Anema react" hashtag="#react">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TelegramIcon round={false} size={36}></TelegramIcon>
                                            </TelegramShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://youtu.be/3aSb1WPK4Kg" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div> */}
                            </article> 
                        </div>
                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team3.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Developer & CIO</p>
                                        <h4>Jennifer Arnold</h4>
                                        
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://youtu.be/3aSb1WPK4Kg" 
                                            quote= "Anema react" hashtag="#react">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TelegramIcon round={false} size={36}></TelegramIcon>
                                            </TelegramShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://youtu.be/3aSb1WPK4Kg" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                            <TwitterShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TwitterIcon round={false} size={36}></TwitterIcon>
                                            </TwitterShareButton>
                                        </li>
                                    </ul>    
                                </div>
                            </article> 
                        </div> */}

                        
                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid" src="img/preview/team4.jpg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Developer</p>
                                        <h4>Jimmy Kllay</h4>
                                        
                                    </div>

                                </figure>
                                <div className="left-icon">
                                <ul className="author-icon list-unstyled">
                                        <li>
                                            <FacebookShareButton url="https://youtu.be/3aSb1WPK4Kg" 
                                            quote= "Anema react" hashtag="#react">
                                                <FacebookIcon round={false} size={36}></FacebookIcon>
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TelegramShareButton url="https://youtu.be/3aSb1WPK4Kg">
                                                <TelegramIcon round={false} size={36}></TelegramIcon>
                                            </TelegramShareButton>
                                        </li>
                                        <li>
                                            <LinkedinShareButton url="https://youtu.be/3aSb1WPK4Kg" >
                                                <LinkedinIcon round={false} size={36}></LinkedinIcon>
                                            </LinkedinShareButton> 
                                        </li>
                                        <li>
                                        <PinterestShareButton url="https://youtu.be/3aSb1WPK4Kg" media="https://youtu.be/3aSb1WPK4Kg">
                                            <PinterestIcon round={false} size={36}></PinterestIcon>
                                        </PinterestShareButton>
                                        </li>
                                    </ul>   
                                </div>
                            </article> 
                        </div> */}
                    </div>
                </div>
            </div>          
        </section>
        </>
    )
}

export default Team
