import React from 'react'

function Welcome2() {
    return (
        <section id="welcome">
            <div className="block color-scheme-1">
                
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-sub-title center">
                                <article className="section-title-body white">
                                    <h1 className="head-title">
                                        <span>FullScope</span> is a modern & creative <span>digital marketing team</span> that specializes in crafting innovative strategies to help businesses thrive in the ever-evolving landscape of the online world.</h1>
                                    <p className="head-text">
                                    Driven by our passion for innovation and our commitment to excellence, we don't just offer services; we deliver comprehensive solutions that transcend conventional marketing approaches. By leveraging our multifaceted expertise and leveraging the latest digital tools and trends, we help businesses not just thrive but dominate their digital presence.
                                    </p>
                                </article>
                            </div>   
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Welcome2
