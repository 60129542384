import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-ios-people-outline',
        title:'Social Media Management',
        text:'Unlock the full potential of your online presence with our expert social media management service. From crafting captivating content to strategically scheduling posts and tracking engagement, we handle it all, so you can focus on what you do best – growing your business. Elevate your brand, engage your audience, and watch your online influence soar with our top-tier social media management solutions. '
    },
    {
        _id:'002',
        classIcon:'ion-ios-monitor-outline',
        title:'Website Services',
        text:'Transform your online presence with our all-in-one website services. Whether you need a brand-new, SEO-optimized website from scratch or want to revamp your existing site for better performance and visibility, we have got you covered. Let us empower your digital presence, ensuring you stand out and succeed online.'
    },
    {
        _id:'003',
        classIcon:'ion-ios-videocam-outline',
        title:'Animations',
        text:'Elevate your brand and captivate your audience with our bespoke animation services. Whether you are looking to enhance your content creation or create eye-catching ads for your products, our team of talented animators is here to bring your vision to life. From stunning visuals to compelling storytelling, we will help you make a lasting impression in the world of animation.'
    },
    {
        _id:'004',
        classIcon:'ion-ios-pricetags-outline',
        title:'Marketing Campaigns',
        text:'Supercharge your brands growth with our dynamic marketing campaigns. Our team of experts crafts tailored strategies that target the right audience, leverage cutting edge tactics, and deliver measurable results. Whether you are looking to boost brand awareness, drive sales, or engage your customers, our campaigns will ensure your message resonates and your business thrives.'
    },
    {
        _id:'005',
        classIcon:'ion-ios-film-outline',
        title:'Video Editing',
        text:'Elevate your content and storytelling with our professional video editing services. Our experienced team combines creativity and technical expertise to transform raw footage into captivating, polished videos that engage and impress your audience. From simple edits to complex post-production, we bring your vision to life, making your videos stand out and shine.'
    },
    {
        _id:'006',
        classIcon:'ion-ios-chatbubble-outline',
        title:'24/7 Hour Support',
        text:'Experience peace of mind with our 24/7 support services, because we are here for you around the clock, whenever you need us. Our dedicated team is always just a call or message away, ready to assist you with any questions, issues, or concerns. With our unwavering support, you can focus on what matters most, knowing we have got your back, day and night.'
    }
]
function Service3() {
    return (
        <>
        <section  id="services">
        <div className="bg-dark"></div> 
            <div className="section-block-title">
                <div className="section-title">   
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-10">
                                <article className="section-title-body">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Services</h1>
                                    <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                    <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                    With a focus on data-driven strategies and creative campaigns, our digital marketing services are designed to deliver measurable results, helping clients achieve their marketing goals and grow their online presence in a highly competitive digital landscape.
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
                <div className="block color-scheme-blur">
                    <div className="container">
                        <div className="row">
                            {services.map((service)=>(
                            <div className="col-lg-4 col-md-6"  key={service._id}>
                                <div className="block-feature">
                                    <i className={service.classIcon}></i>
                                    <a href="/">{service.title}</a>
                                    <p>
                                    {service.text}
                                    </p>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            
        </section>
        </>
    )
}

export default Service3
