import React from 'react';
import ReactDOM from 'react-dom';
import Template from './pages/Home1';
ReactDOM.render(
    <>
       <Template/>
    </>,
  document.getElementById('root')
);

