import React from "react";
// import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
const portfolioAll = [
  {
    _id: "001",
    src: "img/preview/Bang_Promo.png",
    title: "Bang Energy",
    category: "",
    videoUrl: "https://www.youtube.com/watch?v=16QCZGcPDsM",
  },
  {
    _id: "002",
    src: "img/preview/Invader_Promo.png",
    title: "Invader Coffee",
    category: "",
    videoUrl: "https://www.youtube.com/watch?v=7Fh5-GC6XZ8",
  },
  {
    _id: "003",
    src: "img/preview/SS_Promo.png",
    title: "SteelSeries",
    category: "",
    videoUrl: "https://www.youtube.com/watch?v=BladksiF86c&ab_channel=OutfoxedShorts",
  },
  {
    _id: "004",
    src: "img/preview/Gfuel.png",
    title: "Gfuel",
    category: "",
    videoUrl: "https://www.youtube.com/watch?v=CnkDqzbcM_U&ab_channel=Cannaestia",
  },
  {
    _id: "005",
    src: "img/preview/ASUS_Promo.png",
    title: "ASUS",
    category: "",
    videoUrl: "https://www.youtube.com/watch?v=Mm7mHovYJwE&ab_channel=Cannaestia",
  },
  {
    _id: "006",
    src: "img/preview/Manscaped_Promo2.png",
    title: "Manscaped",
    category: "",
    videoUrl: "https://www.youtube.com/watch?v=9mOomx0SEwg",
  },
];

function Portfolio3() {
  return (
    <>
      <section id="portfolio">
        <div className="bg-dark"></div>
        <div className="section-block-title">
          <div className="section-title">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8 col-sm-10">
                  <article className="section-title-body">
                    <h1
                      className="head-title wow fadeInDown animated"
                      data-wow-duration="1.5s"
                    >
                      Portfolio
                    </h1>
                    <div
                      className="section-col wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    ></div>
                    <p
                      className="head-text wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    >
                      Explore a captivating journey through our portfolio, where
                      creativity meets innovation. Our collection showcases a
                      diverse range of projects that exemplify our dedication to
                      craftsmanship, attention to detail, and passion for
                      exceeding expectations. Each piece reflects our commitment
                      to delivering exceptional solutions tailored to our
                      clients' unique needs, making us your trusted partner for
                      all your creative endeavors.
                    </p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block color-scheme-blur">
          <div className="container">
            {/* <SimpleReactLightbox>
              <SRLWrapper> */}
                <div className="row">
                {portfolioAll.map((portfolio) => (
  <div className="col-lg-4 col-md-6" key={portfolio._id}>
    <div className="portfolio-block">
      {/* Conditional rendering to either render a link for video or an image for lightbox */}
      {portfolio.videoUrl ? (
        <a href={portfolio.videoUrl} target="_blank" rel="noopener noreferrer">
          <img alt={portfolio.title} className="img-fluid" src={portfolio.src} />
          <span className="video-play-icon"></span> {/* You can style this accordingly */}
        </a>
      ) : (
        <a href={portfolio.src} data-attribute="SRL">
          <img alt={portfolio.title} className="img-fluid" src={portfolio.src} />
        </a>
      )}
      <div className="portfolio-content">
        <p>{portfolio.category}</p>
        <h4>{portfolio.title}</h4>
      </div>
    </div>
  </div>
))}

                </div>
              {/* </SRLWrapper>
            </SimpleReactLightbox> */}
          </div>
          <div className="clearfix"></div>
        </div>
      </section>
    </>
  );
}

export default Portfolio3;
