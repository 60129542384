import React from 'react'
import ContactForm from './../Contact/ContactForm'

function Contact2() {
    return (
        <>
        <section  id="contact" className="single-bg">
            <div className="bg-dark"></div>
            <div className="block">
                
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                            <h1 className="head-title">You Can <span>Contact</span> us</h1>
                        </article>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 ">
                                    
                                    <div className="text-center white">
                                        {/* <h4 className="widget-title"><i className="ion-home"></i>Address:</h4>
                                        <p>
                                           Do we want a P.O. Box?
                                        </p> */}
                                        {/* <h4 className="widget-title"><i className="ion-android-call"></i>Phone:</h4>

                                        <p>(123) 456-7890<br/>
                                            
                                        </p> */}
                                        <h4 className="widget-title"><i className="ion-email"></i>Email:</h4>
                                        <p> Support@FullScope.com<br/>
                                
                                        </p>
                                    </div>

                            </div>
                            <div className="col-lg-6">
                                <ContactForm/>
                            </div>
                                    
                        </div>
                    </div>
            </div>
        </section>
        </>
    )
}

export default Contact2
